.App {
  text-align: center;
}

.mainNavLinks {
  color: gray;
  background: none;
  border: none;
  cursor: pointer;
  text-decoration: none;
  font-size: inherit;
  font-family: inherit;
  padding: 0;
}

.mainNavLinksActive {
  color: blue;
  text-decoration: underline;
  background: none;
  border: none;
  cursor: pointer;
  font-size: inherit;
  font-family: inherit;
  padding: 0;
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1.25em;
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.categoryCard {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  max-width: 80%;
  margin: auto;
  gap: 20px
}
.categoryCard > .center {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 0;
}
.categoryCard > .center > ul {
  margin-block-start: 0;
  margin-left: 0;
  padding-inline-start: 20px;
}

.categoryCard > div:first-child {
  grid-column: 1 / -1; /* Span all columns */
  justify-content: start;
}

.categoryWrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 80%;
  margin: auto;
}

div ul li {
  list-style: none;
  display: inline-block;
  margin-right: 30px;
  padding-left: 0;
  margin-left: 0;
}

.display-text {
  font-size: 3.5em;
  font-weight: 600;
  text-align: left;
}

.lowPriority {
  font-weight: 300;
}

.containerBtn {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 2fr;
  margin: auto;
  margin-top: 50px;
  align-items: center;
  width: 100%;
  gap: 30px;
}
.gridItem1 {
  text-align: left;
}
.gridItem2 {
  text-align: center;
}

.gridItem3 {
  text-align: right;
}

.nrProprietati {
  font-weight: bold;
  font-size: 16px;
}

.mainBtn {
  padding: 16px 32px;
  font-size: 1.25rem;
  font-weight: 300;
  color: #002031;
  background-color: #FA9F30;
  border: none;
  margin: 25px 0;
  border-radius: 5px; /* Optional: Rounded corners for a softer look */
  transition: background-color 0.3s ease, box-shadow 0.3s ease, transform 0.3s ease;
  cursor: pointer; /* Show pointer cursor on hover */
}

.mainBtn:hover {
  background-color: #e88a1e; /* Darker shade of the background color for hover effect */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Shadow to give depth on hover */
  transform: scale(1.05); /* Slightly enlarges the button on hover */
}

.secondaryButton {
  padding: 10px 12px;
  font-size: 16px;
  font-weight: 300;
  color: #002031;
  background-color: #FA9F30;
  border-radius: 5px;
  border: none;
}

.secondaryButton img {
  margin-right: 10px;
  vertical-align: middle;
}

.filterBtn {
  font-weight: bold;
  font-size: 16px;
}

.filterBtn img {
  margin-right: 10px;
}

.containerLink {
  text-decoration: none;
  color: inherit;
}

.noStyleLink, .noStyleLink:hover, .noStyleLink:focus, .noStyleLink:active {
  text-decoration: none;
  color: inherit;
}

.linkPointer {
  cursor: pointer;
}

.searchBar {
  display: flex;
  justify-content: center;
  margin-top: 150px;
}

.searchInput {
  width: 100%;
  max-width: 600px;
  padding: 10px 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s, box-shadow 0.3s;
}

.searchInput:focus {
  border-color: #FA9F30;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
  outline: none;
}

.deepBlue {
  color: #002031;
}

@media (max-width: 768px) {
  .mainBtn {
    padding: 15px 26px;
    margin: 20px 0;
  }

  .categoryWrapper {
    width: 100%;
    padding: 20px 0;
  }

  .categoryCard {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    margin: auto;
    max-width: 90%;

  }

  .categoryCard > .center > ul {
    overflow-x: hidden;
    padding-inline-start: 10px;
  }

  .searchInput {
    width: 80%;
    max-width: 600px;
    padding: 10px 15px;
    transition: border-color 0.3s, box-shadow 0.3s;
  }

  .searchInput:focus {
    border-color: #FA9F30;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
  }
  .gridItem1 {
    text-align: left;
    font-size: 0.5em;
  }
  .gridItem2 {
    text-align: center;
  }

  .gridItem3 {
    text-align: right;
  }
}