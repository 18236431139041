.footer {
    width: 100vw;
    width: 80%;
    padding: 20px 10%;
    background-color: #002031;
    margin-top: 50px;
}

.white {
    color: #ffffff;
}

.footer li {
    color: white;
    list-style: none;
    text-align: left;
    padding: 3px 0;
    display: block;
}

.footer ul {
    color: white;
    list-style: none;
    text-align: left;
    padding: 0;
    margin: 1em 0;
}

.logoFooter {
    margin: 20px 0;
}

.footerItemsFlex {
    display: flex;
    flex-direction: column;
}

.marginSocial {
    margin-left: 10px;
}

.paddingSocial {
    margin: 1.5em 0;
}

.containerWidthFooter {
    width: 65%;
}

.footer h3 {
    font-size: 1.5em;
    font-weight: bold;
}

.footer p {
    margin-top: 7.5em;
    margin-bottom: 0;
    text-align: center;
    font-size: 0.8em;
}

.footerNav {
    color: white;
    text-decoration: none;
    display: inline-block; /* This enables scaling */
    transition: transform 0.5s;
}

.footerNav:hover {
    color: white;
    transform: scale(1.1); /* Scales up by 10% on hover */
    text-decoration: none;
}

