@import url('https://fonts.googleapis.com/css2?family=Hind:wght@300;400;500;600;700&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Reddit+Sans:ital,wght@0,200..900;1,200..900&family=Space+Grotesk:wght@300..700&family=Ubuntu+Sans:ital,wght@0,100..800;1,100..800&display=swap');

.propertyContainer {
    display: flex;
    flex-direction: column;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    background-color: #F5F8FA;
    width: 100%; /* Adjust width to ensure it's full-width */
    max-width: 265px;
    padding: 0;
    margin: 10px auto;
    transition: box-shadow 0.3s ease; /* Add transition for box-shadow */
}

.propertyContainer:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add box-shadow on hover */
}

.verticalFlex {
    display: flex;
    flex-direction: column;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    font-size: 1.25em;
    color: white;
}

.propertyContent{
    padding: 15px;
}

.productDetail {
    width: 80%;
}

.imageGridProperty {
    width: 100%; /* Ensure image takes up full width of the container */
    height: 220px;
    object-fit: cover; /* Ensures the image covers the entire container without distortion */
    border-top-left-radius: 5px; /* Match border radius with container */
    border-top-right-radius: 5px; /* Match border radius with container */
}

.propertyGridContainer {
    display: grid; /* Use grid instead of flex */
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)); /* Creates responsive columns */
    gap: 20px; /* Adds space between grid items */
    width: 90%;
    justify-content: center;
    align-self: center;
    margin: auto;
    margin-bottom: 50px;
}

.propertyGridContainer > div:first-child {
    grid-column: 1 / -1; /* Span the first div across all columns */
}
/*.homePropertyGridContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width:80%;
    justify-content: center;
    align-self: center;
    margin: auto;
}*/

.homePropertyGridContainer {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    width: 80%;
    justify-content: center;
    align-self: center;
    margin: auto;
}

.propertyWrapperOfWrapper {
    width: 100vw;
    width: 100%;

}

.propertyFlex {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.propertyFlexLeft {
    display: flex;
    flex-direction: row;
    justify-content: left;
}

.propertyFlexLeft div {
    margin-left: 20px;
}

.propertyFlexLeft div:first-child {
    margin-left: 0px;
}

.propertyFlex p {
    text-align: left;
    margin-left: 5%;
}
h3 {
    padding: 0;
    margin: 0;
}

.price {
    font-family: Montserrat;
    color: green;
    font-weight: 600;
    margin-block-start: 0;
}

.wishlistIcon {
    width: 24px;
}

.propertiesCounter {
    font-size: 14px;
    margin-left: 5px;
}

@media (max-width: 768px) {
    .homePropertyGridContainer {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        width: 100%;
        justify-content: center;
        margin: auto;
    }
    .productDetail {
        width: 100%;
    }
    .productDetail > h2 {
        margin: 0;
        margin-block-start: 0;
    }

    .imageGridProperty {
        float: left;
        max-width:  100%;
        height: 250px;
        object-fit: cover;
    }
    .price {
        font-family: Montserrat;
        color: green;
        font-weight: 600;
        font-size: 1.5em;
    }
    .propertyContainer {
        max-width: 90%;
        padding: 0;
        margin: 20px auto;
    }
    h2.price {
        margin: 0;
    }

}