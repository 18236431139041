.agentContainer {
        position: fixed; /* Stay in place */
        z-index: 3000; /* Sit on top */
        left: 0;
        top: 0;
        width: 100%; /* Full width */
        height: 100%; /* Full height */
        overflow: auto; /* Enable scroll if needed */
        background-color: rgb(0,0,0); /* Fallback color */
        background-color: rgba(0,0,0,0.2); /* Black w/ opacity */
        display: flex;
        justify-content: center;
        align-items: center;
}

.agentContainer .content {
        /* Styles for the content */
        background-color: white; /* Or any desired color */
        padding: 20px; /* Adjust padding as needed */
        border: #002031 1px solid;
        border-radius: 5px;
        width: 30%;
}

.agentHidden {
        display: none;
}

.flexRow {
        display: flex;
        flex-direction: row;
        justify-content: left;
}

.flexItem {
        margin: 0 10px;
        width: 40%;
        text-align: center;
}

.contactFormWrapper {
        position: relative;
}

.closeButtonIco {
        position: relative;
        padding: 0;
        background: transparent;
        border: none;
        cursor: pointer;
        align-self: flex-end;
        color: #002031;
        z-index: 9000;
        transition: transform 0.3s ease;
}

.closeButtonIco:hover {
        transform: scale(1.3);
        background: transparent;
}

.closeGl {
        width: 20px;
}

.content {
        display: flex;
        flex-direction: column;
}



.unstyledLink {
        text-decoration: none;
        color: #002031;
        text-size: 1em;
        font-weight: 600;
}

.agentName {
        font-size: 2em;
        margin: 0;
        font-weight: 600;
        padding-right: 10px;
}

.agentPic {
        float: left;
        width:  125px;
        height: 125px;
        border-radius: 100px;
        object-fit: cover;
        object-position: top center;
        margin: 0 1em;
        margin-bottom: 1.5em;
}

.agentTitle {
        font-size: 1.25em;
        margin: 0;
}