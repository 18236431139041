.wishlist-container {
    padding: 2rem;
    text-align: center;
    padding-top: 75px;
    background-color: #f9f9f9; /* Light background for contrast */
}

h2 {
    margin-bottom: 2rem;
    font-size: 2.5rem;
    color: #333; /* Darker text color for better readability */
}

.wishlist-items {
    display: grid; /* Use grid layout */
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr)); /* Responsive grid */
    gap: 2rem; /* Space between grid items */
    justify-items: center; /* Center items within their grid cells */
}

.wishlist-item {
    width: 100%; /* Full width for grid items */
    border: 1px solid #eaeaea;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    text-align: left;
    transition: box-shadow 0.3s ease-in-out;
}

.wishlist-item:hover {
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.15);
}

.wishlist-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
}

.wishlist-info {
    padding: 1rem;
    background-color: white; /* White background for info section */
}

.wishlist-info h3 {
    margin: 0;
    font-size: 1.5rem;
    font-weight: bold;
    color: #333;
}

.wishlist-info p {
    margin: 0.5rem 0;
    font-size: 1rem;
}

.wishlist-price {
    font-size: 1.2rem;
    font-weight: bold;
    color: #2b8a3e;
}

.explore-btn {
    display: inline-block;
    margin-top: 2rem;
    padding: 0.8rem 1.5rem;
    background-color: #2b8a3e;
    color: white;
    text-decoration: none;
    border-radius: 8px;
    transition: background-color 0.3s ease-in-out;
}

.explore-btn:hover {
    background-color: #267634;
}

/* Media Queries for Responsive Design */
@media (max-width: 768px) {
    h2 {
        font-size: 2rem; /* Smaller font size on mobile */
    }

    .explore-btn {
        padding: 0.6rem 1.2rem; /* Adjust button padding */
    }
}

@media (max-width: 480px) {
    .wishlist-price {
        font-size: 1rem; /* Smaller price font on mobile */
    }
}
