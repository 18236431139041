.flexBox {
    display: flex;
    flex-direction: column;
    align-self: left;
    align-items: flex-start;
    margin: 20px;
    flex-wrap: wrap;
}

.flexBox img {
    display: inline-block;
    min-width: 100%;
    transition: 0.5s;
    transition-all: all;
}

.flexBox img:hover {
    display: inline-block;
    scale: 1.025;
}

.amount {
    padding: 0;
    margin: 0;
}

@media (max-width: 768px) {
    .flexBox img {
        max-width: 200px;
    }
}

