@import '@radix-ui/colors/black-alpha.css';
@import '@radix-ui/colors/mauve.css';
@import '@radix-ui/colors/violet.css';

.ScrollAreaRoot {
    width: 30vw;
    height: 100vh;
    border-radius: 4px;
    overflow: hidden;
    box-shadow: 0 2px 10px var(--black-a7);
    background-color: white;
    --scrollbar-size: 2px;
    position: fixed;
    right: -30vw; /* Start off-screen */
    top: 0;
    transform: translateX(100%); /* Start hidden */
    transition: right 0.3s ease-out, transform 0.3s ease-out;
    z-index: 3000;
}

/* Responsive adjustment to span 90vw */
@media (max-width: 768px) {
    .ScrollAreaRoot {
        width: 90vw; /* Expand to 90% of the viewport width on mobile */
        right: -90vw; /* Start off-screen */
        transition: right 0.3s ease-out, transform 0.3s ease-out;
    }
}

/* Class for hiding the filter bar */
.HiddenFilterBar {
    right: -30vw; /* Off-screen */
    transform: translateX(100%); /* Hidden */
}

@media (max-width: 768px) {
    .HiddenFilterBar {
        right: -90vw; /* Off-screen on mobile */
    }
}

/* Class for showing the filter bar */
.ShowFilterBar {
    right: 0; /* Slide in from the right */
    transform: translateX(0); /* Visible */
}

.ScrollAreaViewport {
    width: 100%;
    height: 100%;
    border-radius: inherit;
}

.ScrollAreaScrollbar {
    display: flex;
    user-select: none;
    touch-action: none;
    padding: 2px;
    background: var(--black-a6);
    transition: background 160ms ease-out;
}

.ScrollAreaScrollbar:hover {
    background: var(--black-a8);
}

.ScrollAreaScrollbar[data-orientation='vertical'] {
    width: var(--scrollbar-size);
}

.ScrollAreaScrollbar[data-orientation='horizontal'] {
    flex-direction: column;
    height: var(--scrollbar-size);
}

.ScrollAreaThumb {
    flex: 1;
    background: var(--mauve-10);
    border-radius: var(--scrollbar-size);
    position: relative;
}

.ScrollAreaThumb::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    min-width: 44px;
    min-height: 44px;
}

.ScrollAreaCorner {
    background: var(--black-a8);
}

.Text {
    font-size: 1.25em;
    line-height: 0.25em;
    font-weight: bold;
    padding: 5px 0;
}

.Tag {
    color: var(--mauve-12);
    font-size: 13px;
    line-height: 18px;
    margin-top: 10px;
    border-top: 1px solid var(--mauve-6);
    padding-top: 10px;
}

.checkbox-container {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}

.checkbox-container input[type="checkbox"] {
    width: 20px;
    height: 20px;
    margin-right: 8px;
    cursor: pointer;
    appearance: none;
    background-color: #fff;
    border: 2px solid #007BFF;
    border-radius: 4px;
    position: relative;
    outline: none;
    transition: background-color 0.3s, border-color 0.3s;
}

.checkbox-container input[type="checkbox"]:checked {
    background-color: #007BFF;
    border-color: #007BFF;
}

.checkbox-container input[type="checkbox"]:checked::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 6px;
    height: 12px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: translate(-50%, -50%) rotate(45deg);
}

.checkbox-container label {
    cursor: pointer;
    font-size: 14px;
    color: #333;
}

.filterHeading {
    font-size: 1.25em;
    margin-bottom: 1em;
    font-weight: 500;
}

.closeButton {
    background: transparent;
    border: none;
    font-size: 1.5rem;
    position: absolute;
    top: 0.25em;
    right: 1em;
    cursor: pointer;
    color: #002031;
    transition: color 0.3s ease, transform 0.3s ease, background-color 0.3s ease; /* Added transition for smooth hover effect */
}

.closeButton:hover {
    color: #002031;
    background-color: #FA9F30;
    transform: rotate(180deg) scale(1.1); /* Rotate and slightly scale the button on hover */
}

.closeButton:active {
    transform: rotate(180deg) scale(0.9); /* Reduce scale when clicked for a press effect */
}
