.homeHeader {
    position: relative;
    min-height: 100vh;
    background-image: url("../images/headerImg4.png");
    background-size: cover;
    background-position: center center;
    width: 100vw;
    display: flex;
    box-sizing: border-box;
    max-width: 100%;
    overflow: hidden; /* Ensure any overflow is hidden */
}

.homeHeader::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.45); /* Adjust the opacity as needed */
    pointer-events: none; /* Ensure the overlay doesn't block interactions with content */
}

.homeHeader * {
    position: relative;
    z-index: 1; /* Ensure all content inside .homeHeader is above the overlay */
}


.headerContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;
    align-items: flex-start;
    padding: 0 10%;
}

.headerContent p {
    color: white;
}

.headerText {
    margin: 0;
    margin-top: -20px;
    color: white;
}

/* Mobile styles (for screens 768px and smaller) */
@media (max-width: 768px) {
    .homeHeader {
        min-height: 70vh; /* Reduce the height for mobile */
        background-position: center center; /* Keep background centered */
        justify-content: center;
    }

    .headerContent {
        padding: 0 20px; /* Reduce padding for smaller screens */
        align-items: center; /* Center content horizontally */
        text-align: center; /* Center-align text */
    }

    .headerText {
        margin-top: 0; /* Remove negative margin for better spacing on mobile */
        text-align: center;
    }
}

/* Additional mobile styles (for screens 480px and smaller) */
@media (max-width: 480px) {
    .homeHeader {
        min-height: 70vh; /* Further reduce height */
    }

    .headerContent {
        padding: 0 20px; /* Even less padding on very small screens */
    }

    .headerText {
        font-size: 3em; /* Adjust font size for smaller screens */
    }
}
