/* src/Components/Spinner.css */
.spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.spinner {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-left: 3px solid #FA9F30; /* Color of the spinner */
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1.5s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
/* Add this to your existing CSS file */
.spinner-wrapper {
    display: flex;
    justify-content: center; /* Horizontally center */
    align-items: center;     /* Vertically center */
    padding: 5em;            /* Padding around the spinner */
    width: 100vw;            /* Full viewport width */
    height: 100vh;           /* Full viewport height */
    box-sizing: border-box;  /* Ensure padding is included in total width/height */
    position: fixed;         /* Fixed position to cover the screen */
    top: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.8); /* Optional: Background with slight opacity */
    z-index: 9999;           /* Ensure it's on top of other content */
}
