.separator {
    /*width: 100vw;*/
    height: 400px;
    /*max-width: 90%;*/
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-items: center;
    background-repeat: no-repeat;
    background-image: url("../images/separatorBg.png");
    background-size: cover;
    background-position: center center;
    text-align: left;
    padding-left: 10%;
    margin-top: 50px;
}

