nav ul li {
    list-style: none;
    display: inline-block;
    margin: 5px 30px;
}

header.transparent {
    background-color: transparent; /* Transparent background */
    transition: background-color 0.3s ease; /* Smooth transition */
}

header.white-background {
    background-color: white; /* White background after scroll */
    transition: background-color 0.3s ease;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Add shadow for effect */
}

/* Styling for nav elements when background is white */
header.white-background .mainNavLinks,
header.white-background .hamburger {
    color: #002031; /* Use default dark color */
}

/* Styling for nav elements when background is transparent */
header.transparent .mainNavLinks,
header.transparent .hamburger {
    color: white; /* White text for transparent background */
}

/* Wrapper for the hamburger and wishlist */
.menu-wishlist-wrapper {
    display: flex;
    align-items: center;
}

.mainNavLinks {
    color: #002031;
    font-size: 18px;
    font-weight: 400;
    text-decoration: none;
    position: relative;
}

.mainNavLinks::after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #FA9F30;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
}

.mainNavLinks:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
}

.mainNavLinksActive {
    color: #002031;
    font-size: 18px;
    font-weight: 400;
    text-decoration: none;
    position: relative;
    display: inline-block;
}

.mainNavLinksActive::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #FA9F30;
}

/* Default logo size */
header.white-background .logo img {
    width: 200px;  /* Default size */
    transition: width 0.5s ease, transform 0.5s ease;  /* Smooth transition */
}

/* Larger logo when navbar is transparent */
header.transparent .logo img {
    width: 250px;  /* Larger size when transparent */
    transform: scale(1.1);  /* Optional: Slight scaling effect for emphasis */
}


.LogoHeader {
    width: 200px;
}

.LogoHeaderWhite {
    width: 300px;
}

header {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 3000;
}

.headerFlex {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100vw;
    max-width: 100%;
    /*background-color: white;*/
    padding: 15px 5%;
    box-sizing: border-box;
    align-items: center;
}

.mainNav {
    width: 100vw;
    max-width: 100%;
    background-color: white;
}

.phoneHolder {
    background-color: #002031;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: white;
    height: 26px;
    font-size: 14px;
}

.phoneHolder img {
    padding-right: 10px;
}

/* Basic Navigation Styles */


nav ul {
    list-style: none;
    display: flex;
    padding: 0;
    margin: 0;
}

nav li {
    margin-right: 30px;
}



/* Hamburger Button */
.hamburger {
    display: none; /* Hidden on desktop */
    font-size: 30px;
    background: none;
    border: none;
    cursor: pointer;
}

.wishlist-count {
    background-color: red;
    color: white;
    border-radius: 50%;
    padding: 0.2em 0.5em;
    font-size: 0.8em;
    position: absolute;
    top: -10px;
    right: -10px;
}

.heartIconMenu {
    width: 20px;
}

@media (min-width: 769px) {
    .menu-wishlist-wrapper {
        display: none; /* Hide the wishlist next to the hamburger on desktop */
    }
}

/* Mobile Styles */
@media (max-width: 768px) {
    .menu-wishlist-wrapper {
        gap: 10px; /* Add some space between the hamburger and wishlist */
    }

    .wishlistDsk {
        display: none;
    }

    .hamburger {
        display: block; /* Show hamburger on mobile */
    }

    nav {
        display: none; /* Hide nav by default on mobile */
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        background-color: white;
        border-top: 1px solid #ccc;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }

    nav.nav-open {
        display: block; /* Show nav when menu is open */
        position: absolute;
        top: 100px;
        left: 0;
        right: 0;
        background-color: white;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        z-index: 100;
    }
    nav.nav-open .mainNavLinks,
    nav.nav-open .hamburger {
        color: #002031; /* Default dark color */
    }

    nav ul {
        flex-direction: column;
        align-items: center;
        gap: 30px;
        margin: 30px 0;
    }

    nav li {
        margin: 15px 0;
    }
}