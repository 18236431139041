/* ImageGallery.css */
@import "~react-image-gallery/styles/css/image-gallery.css";

/* Main gallery container */
.ImageGallery {
    width: 100%; /* Allow it to fill the parent container */
    max-width: 1000px; /* Set a maximum width for the gallery */
    margin: 0 auto; /* Center the gallery */
    display: flex; /* Use flexbox for layout */
    flex-direction: column; /* Stack elements vertically */
    align-items: center; /* Center items */
    position: relative; /* Position relative to allow absolute positioning of arrows */
}

/* Positioning the left arrow */
.image-gallery-left-nav.galleryBtn {
    left: 15px; /* Distance from the left edge of the container */
}

/* Positioning the right arrow */
.image-gallery-right-nav.galleryBtn {
    right: 15px; /* Distance from the right edge of the container */
}

/*Full Screen Style*/

.ImageGallery .fullscreen .image-gallery-slide {
    height: 100vh; /* Full viewport height */
    display: flex; /* Use flexbox to center the image */
    align-items: center; /* Vertically center the image */
    justify-content: center; /* Horizontally center the image */
}

.ImageGallery .fullscreen .image-gallery-slide img {
    object-fit: contain; /* Ensure the image covers the entire area */
    height: 100vh; /* Set height to fill the viewport */
    min-height: 100vh;
    width: 100%;
    display: block; /* Block display for proper sizing */
}


/* Main image styling */
.image-gallery-slide {
    display: flex;
    align-items: center; /* Vertically centers the image */
    justify-content: center; /* Horizontally centers the image */
    object-fit: cover;
    height: 70vh; /* Ensures the slide takes up the specified height */
}

.image-gallery-slide img {
    object-fit: cover; /* Ensure image covers the area without distortion */
    width: 100%; /* Full width */
    height: 70vh; /* Adjust height based on your preference */
    display: block; /* Ensure block-level for proper alignment */
}

/* Thumbnail wrapper styling */
.image-gallery-thumbnails-wrapper {
    display: flex;
    justify-content: center; /* Center the thumbnails */
    overflow-x: auto; /* Allow horizontal scrolling */
    width: 100vw; /* Make thumbnails span the entire width of the page */
    padding: 5px 0; /* Optional padding */
}

/* Thumbnail styles */
.image-gallery-thumbnail {
    margin: 0 5px; /* Spacing between thumbnails */
    cursor: pointer; /* Change cursor to pointer */
    height: 100px; /* Fixed thumbnail height */
    overflow: hidden; /* Hide any overflow */
    display: inline-block; /* Ensure thumbnails stay in a single row */
    vertical-align: middle; /* Align thumbnails vertically */
}

/* Thumbnail image styles */
.image-gallery-thumbnail img {
    object-fit: cover; /* Maintain aspect ratio */
    height: 100%; /* Ensure the height is consistent */
    width: auto; /* Allow the width to adjust */
    display: block;
    margin: auto; /* Center the image inside the thumbnail container */
}

.galleryBtn {
    background-color: rgba(0, 32, 49, 0.8); /* Semi-transparent background */
    color: white;
    padding: 10px !important;
    padding-border-left: 10px !important;
    position: absolute;
    top: 50%; /* Vertically center the arrows */
    transform: translateY(-50%); /* Ensure perfect vertical alignment */
    z-index: 10; /* Ensure the arrows appear above the image */
    cursor: pointer;
    border: none;
}

/* Rest of CSS remains unchanged... */

.containerProductPage {
    width: 80%;
    display: flex;
    flex-direction: row;
    margin: auto;
    margin-top: 50px;
    justify-content: space-between;
}

.noStyleBtn {
    border: none;
    background-color: transparent;
}

.largeHeart {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.wishlist-text {
    text-wrap: nowrap;
}

.largeHeart img {
    max-width: 30px;
    padding-right: 15px;
}

.padding-Top {
    padding-top: 110px;
}

.containerProductPage h2 {
    margin: 0;
}

.containerNarrow {
    width: 80%;
    margin: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 0px;
    align-items: center;
}

.containerNarrow img {
    margin-right: 10px;
    height: 23px;
}

.spacing {
    margin-right: 30px;
    margin-top: 30px;
    margin-bottom: 30px;
}

.flexColumnNarrow {
    width: 80%;
    display: flex;
    flex-direction: column;
    margin: auto;
}

.flexColumnNarrow p {
    margin-top: 0;
    color: #02354E;
}

.flexColumnNarrow h2 {
    margin: 30px 0;
}

.descriptionRow {
    margin: 0.5em 0;
    font-size: 1.25em;
}

.PropertyName {
    font-size: 3em;
}

.read-more-toggle {
    color: #FA9F30; /* Adjust the color as needed */
    text-decoration: none; /* Remove underline */
    font-weight: bold; /* Optional, for emphasis */
    cursor: pointer;
}

.read-more-toggle:hover {
    text-decoration: underline; /* Add underline on hover */
}

@media (max-width: 768px) {
    .PropertyName {
        font-size: 1.5em;
    }

    .PropertyName h2 {
        padding: 0;
        margin: 0;
    }

    .containerProductPage {
        flex-direction: column;
        margin: auto;
        margin-top: 0px;
        justify-content: space-between;
        margin-block-start: 0;
        gap: 20px;
    }

    .containerProductPage h2 {
        margin: 10px 0;
    }
}
