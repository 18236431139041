.contact-page {
    padding: 40px;
    max-width: 1200px;
    margin: 0 auto;
}

.contact-page h1 {
    font-size: 36px;
    margin-bottom: 20px;
    color: #002031;
}

.contact-map {
    margin-top: 50px;
}

.contact-page p {
    font-size: 16px;
    margin-bottom: 30px;
}

.contact-details {
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.contact-info h2, .contact-form h2 {
    font-size: 24px;
    margin-bottom: 15px;
    color: #002031;
}

.contact-info p {
    font-size: 18px;
    margin: 10px 0;
}

.contact-form {
    background-color: #f8f8f8;
    padding: 20px;
    border-radius: 8px;
    box-sizing: border-box;
}

.form-group {
    margin-bottom: 20px;
}

.form-group label {
    display: block;
    margin-bottom: 8px;
    margin-left: 5px;
    font-size: 16px;
    color: #002031;
}

.contact-form input,
.contact-form textarea {
    width: 100%;
    padding: 15px;
    font-size: 16px;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-sizing: border-box; /* Fixes the overflow issue */
}

.form-group input:focus, .form-group textarea:focus {
    outline: none;
    border-color: #fa9f30;
}



@media (min-width: 768px) {
    .contact-details {
        flex-direction: row;
        justify-content: space-between;
    }

    .contact-info {
        flex: 1;
        padding-right: 40px;
    }

    .contact-form {
        flex: 1;
    }
}
