.RealEstatePropsWrapper {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: 1;
    margin: auto;
    margin-bottom: 20px;
    align-items: left;
    width: 80%;
}

.RealEstatePropsWrapperFullWidth {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: 2;
    grid-row-gap: 30px;
    margin: auto;
    margin-bottom: 20px;
    align-items: left;
    width: 100%;
}

.RealEstatePropsContainer {
    display: flex;
    flex-direction: column;
}

.h4REprops {
    font-size: 1.5em;
    font-weight: 400;
    margin: 0px;
}

.RealEstatePropsProperties {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 20px;
}

.iconSet {

    margin-right: 10px;
    height: 27px;
}

.Props {
    color: #02354E;
    font-size: 24px;
}

.realEstateListing {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    width: 80%;
}

@media (max-width: 768px) {
    .RealEstatePropsWrapper {
        grid-template-columns: repeat(2, 1fr);
        margin: auto;
        margin-top: 50px;
        margin-bottom: 50px;
        align-items: left;
        width: 80%;
        gap: 30px;
    }

    .h4REprops {
        font-size: 1.25em;

    }

    .RealEstatePropsWrapperFullWidth {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: 2;
        grid-row-gap: 30px;
        margin: auto;
        margin-bottom: 20px;
        align-items: left;
        width: 100%;
        gap: 40px;
    }

    .realEstateListing {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        width: 80%;
    }
}