/* src/pages/Projects.css */
.projects-container {
    padding: 40px;
    max-width: 1200px;
    margin: auto;
    text-align: left;
    margin-top: 50px;
}

.ourProjects {
    font-size: 2.5rem;
    margin-bottom: 20px;
    color: #333;
}

.projects-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    justify-items: center;
}


.project-card {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.3s, box-shadow 0.3s;
}

.project-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.project-image {
    width: 100%;
    height: auto;
    object-fit: cover;
}

.project-title {
    font-size: 1.5rem;
    margin: 15px;
    color: #333;
}

.project-description {
    font-size: 1rem;
    margin: 0 15px 15px;
    color: #666;
}

@media (max-width: 768px) {
    .projects-grid {
        grid-template-columns: 1fr;
    }
}
