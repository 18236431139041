.property-details {
    width: 100%; /* Ensure the property-details container takes up full width */
    max-width: 1000px; /* Optional max width */
    margin: 20px auto;
    font-family: Arial, sans-serif;
}

.property-details h3 {
    font-size: 1.5em;
    margin-bottom: 15px;
    color: #02354E;
}

/* Grid layout for two columns filling 100% width without gap */
.property-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Two equal columns */
    width: 100%; /* Ensure the grid stretches 100% of the container width */
    gap: 20px;
}

/* Property column styling */
.property-column {
    display: flex;
    flex-direction: column;
}

.property-table {
    width: 100%; /* Each table stretches 100% of its column */
    border-collapse: collapse;
    border: none; /* Remove the outside borders */
}

.property-table td {
    padding: 10px 15px;
    border-bottom: 1px solid #ddd; /* Keep only the inner row borders */
    border-right: none; /* Remove right border */
    border-left: none; /* Remove left border */
}

.property-label, .property-value {
    width: 50%; /* Ensure label and value cells each take up half the table */
}

/* Remove label background and adjust font */
.property-label {
    font-weight: bold;
    background-color: transparent; /* Remove label background */
}

.property-value {
    color: #666;
}

/* Responsive design: single column on smaller screens */
@media (max-width: 768px) {
    .property-grid {
        grid-template-columns: 1fr; /* Single column layout for small screens */
        gap: 0px;
    }
}
